import React from 'react';
import Layout from '../../components/Layout';
import SEO from '../../components/seo';
import Wiki from '../../components/Wiki';
import { TextPreFooter } from '../../components/PreFooter';
import styles from './styles/wiki.module.scss';

const WikiTemplate = ({ pageContext }) => {
  return (
    <Layout
      basePath={pageContext.baseUrl}
      footerClassName={styles.layoutFooter}
      transparent={true}
    >
      <SEO
        description="Explore and compare car, house, contents, life insurance and more."
        title="Types of insurance"
      />
      <Wiki {...pageContext} />
      <TextPreFooter
        subTitleTop="Quashed Market Scan"
        title="We'll help you compare policies and prices in minutes!"
        buttonText="Try Market Scan today"
      />
    </Layout>
  );
};

export default WikiTemplate;

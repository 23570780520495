import React, { useMemo } from 'react';
import classnames from 'classnames';
import AccordionList from './AccordionList';
import { getWikiDescFromNode } from '../common/utils';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import styles from './styles/Wiki.module.scss';
import Dropdown from '../components/Dropdown';
import PageHeader from './sections/PageHeader';
import WikiMSBanner from './WikiMSBanner';

const insuranceLabelMap = {
  insurance: 'Car',
  car: 'Car',
  house: 'House',
  contents: 'Contents',
  health: 'Health',
  life: 'Life',
  tpd: 'Total Permanent Disability',
  pet: 'Pet',
  mortgage: 'Mortgage Repayment',
  redundancy: 'Redundancy',
  trauma: 'Trauma',
  income: 'Income Protection',
  boat: 'Boat',
  motorcycle: 'Motorcycle',
  cancer: 'Cancer',
};

const msSupported = ['Car', 'House', 'Contents', 'Life', 'Motorcycle'];

const Wiki = (props) => {
  const { className, insuranceTypes, currentSlug, baseUrl } = props;
  const images = useStaticQuery(graphql`
    {
      car: file(relativePath: { eq: "insuranceTypes/car.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      house: file(relativePath: { eq: "insuranceTypes/house.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      contents: file(relativePath: { eq: "insuranceTypes/contents.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      health: file(relativePath: { eq: "insuranceTypes/health.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      life: file(relativePath: { eq: "insuranceTypes/life.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tpd: file(relativePath: { eq: "insuranceTypes/tpd.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pet: file(relativePath: { eq: "insuranceTypes/pet.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mortgage: file(relativePath: { eq: "insuranceTypes/mortgage.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      redundancy: file(relativePath: { eq: "insuranceTypes/redundancy.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      trauma: file(relativePath: { eq: "insuranceTypes/trauma.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      income: file(relativePath: { eq: "insuranceTypes/income.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      boat: file(relativePath: { eq: "insuranceTypes/boat.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      motorcycle: file(relativePath: { eq: "insuranceTypes/motorcycle.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cancer: file(relativePath: { eq: "insuranceTypes/cancer.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      policyFiles: file(relativePath: { eq: "policyFiles.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const slugSplit = currentSlug.split('-');
  const slugInsuranceType = slugSplit[0];
  const insuranceLabel = insuranceLabelMap[slugInsuranceType];

  const showWikiMsBanner = msSupported.includes(insuranceLabel);

  const items = useMemo(() => getWikiDescFromNode(props), [props]);

  const renamedInsuranceTypes = insuranceTypes.map((obj) => {
    const { name, slug } = obj;
    return { label: name, link: slug };
  });

  return (
    <div className={classnames(styles.container, className)}>
      <PageHeader
        className={styles.pageHeader}
        title={'Types of insurance'}
        titleClassName={styles.title}
        wrapClassName={styles.headerWrap}
        subtitle={
          <Dropdown
            baseUrl="/insurance-types/"
            className={styles.dropdown}
            label={insuranceLabel}
            items={renamedInsuranceTypes}
            menuClassName={styles.menu}
          />
        }
        image={
          <Image fluid={images[slugInsuranceType].childImageSharp.fluid} />
        }
      />
      {showWikiMsBanner && <WikiMSBanner baseUrl={baseUrl} />}

      <div className={styles.content}>
        <AccordionList contentClassName={styles.accordionList} items={items} />
      </div>
    </div>
  );
};

export default Wiki;

import React from 'react';
import styles from './styles/wikiMsBanner.module.scss';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import LinkButton from './LinkButton';

const WikiMSBanner = (props) => {
  const { baseUrl = '' } = props;

  const images = useStaticQuery(graphql`
    {
      policyFiles: file(relativePath: { eq: "policyFiles.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <Image
          className={styles.img}
          fluid={images.policyFiles.childImageSharp.fluid}
        />
        <div className={styles.tryMsText}>
          <h1>Try Market Scan and save</h1>
          <h2>
            Grab your vehicle policy PDFs and upload them, or simply enter a few
            bits of information. No hidden fees - policy prices are direct from
            insurer.
          </h2>
        </div>
      </div>
      <div className={styles.bottom}>
        <h1>
          The average Kiwi household can save $2,023.79 a year by shopping their
          insurance!
        </h1>
        <h2>
          Find out how your renewal prices stack up against other options out
          there!
        </h2>
        <LinkButton
          className={styles.button}
          href={`${baseUrl}/get-started`}
          background="#54A702"
        >
          Try Market Scan
        </LinkButton>
      </div>
      <div className={styles.disclaimer}>
        <p>
          *Household average savings is calculated assuming: 2 x Cars, 1 x House
          and 1 x Contents insurance policy. Average price difference and median
          price difference are calculated from data generated by Market Scan
          across thousands of quotes from 1 Jan 23 to 31 Mar 23
        </p>
      </div>
    </div>
  );
};

export default WikiMSBanner;

import React from 'react';
import cx from 'classnames';
import CurveDown from '../../images/layout/curve-down.svg';
import styles from './styles/PageHeader.module.scss';
import LinkButton from '../LinkButton';

const PageHeader = ({
  className,
  subtitleClassName,
  titleClassName,
  leftClassName,
  rightClassName,
  wrapClassName,
  image,
  subtitle,
  subtitleTop,
  title,
  hideArrow,
  buttonText,
  buttonLink,
  buttonEvent,
  disclaimer,
}) => {
  return (
    <div className={cx(styles.container, className)}>
      <div className={cx('container', styles.wrap, wrapClassName)}>
        <div className={cx(styles.left, leftClassName)}>
          {subtitleTop && (
            <h2 className={cx(styles.subtitle, subtitleClassName)}>
              {subtitleTop}
            </h2>
          )}
          <h1 className={titleClassName}>{title}</h1>
          {subtitle && (
            <h2 className={cx(styles.subtitle, subtitleClassName)}>
              {subtitle}
            </h2>
          )}
          {buttonText && (
            <LinkButton
              href={buttonLink}
              background="#4187F5"
              className={styles.blueBtn}
              eventName={buttonEvent}
            >
              {buttonText}
            </LinkButton>
          )}

          {disclaimer && <div className={styles.disclaimer}>{disclaimer}</div>}
        </div>
        <div className={cx(styles.right, rightClassName)}>{image}</div>
      </div>
      {!hideArrow && <CurveDown className={styles.curve} />}
    </div>
  );
};

export default PageHeader;
